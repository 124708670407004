import React from 'react'
import { Layout } from '@/templates/Layout'
import { Box, Container, Heading, Link, Paragraph } from 'theme-ui'

export function NotFoundPage() {
  return (
    <Layout>
      <Box as="section">
        <Container
          sx={{
            paddingY: [7, 11],
            textAlign: 'center',
          }}
        >
          <Heading
            variant="subHeadLarge"
            sx={{
              color: 'neutral.700',
              mb: 3,
            }}
          >
            404
          </Heading>
          <Heading>Page not found</Heading>
          <Paragraph>
            Sorry 😔—we couldn{"'"}t find what you were looking for.
          </Paragraph>
          <Paragraph>Need help finding something?</Paragraph>
          <Paragraph>
            <Link href="mailto:support@yourheights.com">Contact us</Link>
          </Paragraph>
        </Container>
      </Box>
    </Layout>
  )
}
