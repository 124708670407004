
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    /* eslint "@typescript-eslint/no-var-requires": "off" */
import React, { FC } from 'react';
import { withPrepass } from '@/utils/server';
import { GetStaticProps } from 'next';
import { NotFoundPage } from '@/components/NotFoundPage';
const Error404: FC = () => {
    return <NotFoundPage />;
};
const getStaticProps: GetStaticProps = async ({ locale }) => {
    return await withPrepass({
        props: {},
        locale,
    }, Error404);
};
export default Error404;

    async function __Next_Translate__getStaticProps__18f39bb207d__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__18f39bb207d__ as getStaticProps }
  